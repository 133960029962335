import {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { BodyLayout } from "./BodyLayout/BodyLayout"
import { HeaderLayout } from "./BodyLayout/HeaderLayout"

import { MainNav } from "./MainNav/MainNav"
import { SecondaryNav } from "./SecondaryNav/SecondaryNav"

export type ModuleMetadata<TAppFeatures> = {
	feature?: TAppFeatures
	icon?: ReactNode
	config?: { hideVesselSelection?: boolean; hideHeader?: boolean }
	name: string
	content: ReactNode
	alternativeContent?: { constraintName: string; content: ReactNode }[]
}

interface IPageLayoutProps<TAppFeatures> {
	t: ReturnType<typeof useTranslation>["t"] | undefined
	company?: string
	contents: ModuleMetadata<TAppFeatures>[]
	defaultModule: string
	children: ReactNode | ReactNode[]
	submitAction?: (name: string) => void
}

interface IPageLayoutContext {
	selectedModule: string
	setSelectedModule: Dispatch<SetStateAction<string>>
	selectedModuleMetadata?: ModuleMetadata<unknown>
	mainItems: { name: string; icon: ReactNode }[]
	subItems?: string[]
	isMainNavExpanded: boolean
	setIsMainNavExpanded: Dispatch<SetStateAction<boolean>>
	isMainNavPopupExpanded: boolean
	setIsMainNavPopupExpanded: Dispatch<SetStateAction<boolean>>
	isSecondaryNavExpanded: boolean
	setIsSecondaryNavExpanded: Dispatch<SetStateAction<boolean>>
	translationFunc: ReturnType<typeof useTranslation>["t"] | undefined
	submitAction?: (name: string) => void
}

const PageLayoutContext = createContext<IPageLayoutContext | undefined>(undefined)

function ComponentBody<TAppFeatures>({
	contents,
	defaultModule,
	children,
	t,
	submitAction,
}: Omit<IPageLayoutProps<TAppFeatures>, "company">) {
	const params = useParams()
	const [selectedModule, setSelectedModule] = useState<string>(
		params.moduleName !== undefined ? params.moduleName.toLowerCase() : defaultModule
	)
	const isMainNavExpandedLocalStorage = localStorage.getItem("isMainNavExpanded")
	const [isMainNavExpanded, setIsMainNavExpanded] = useState<boolean>(
		isMainNavExpandedLocalStorage === "false" ? false : true
	)

	const [isMainNavPopupExpanded, setIsMainNavPopupExpanded] = useState<boolean>(false)

	// const isSecondaryNavExpandedLocalStorage = localStorage.getItem("isSecondaryNavExpanded")
	const [isSecondaryNavExpanded, setIsSecondaryNavExpanded] = useState<boolean>(
		true
		// isSecondaryNavExpandedLocalStorage === "false" ? false : true
	)
	useEffect(() => {
		if (params.moduleName !== selectedModule) {
			setSelectedModule(
				params.moduleName !== undefined ? params.moduleName.toLowerCase() : defaultModule
			)
		}
	}, [params.moduleName])

	const selectedContent = contents.find((c) => c.name === selectedModule)
	const memoizedContextValue: IPageLayoutContext = useMemo(
		() => ({
			selectedModule,
			setSelectedModule,
			selectedModuleMetadata: selectedContent,
			mainItems: contents.map((c) => {
				return { name: c.name, icon: c.icon }
			}),
			isMainNavExpanded: isMainNavExpanded,
			setIsMainNavExpanded: setIsMainNavExpanded,
			isMainNavPopupExpanded: isMainNavPopupExpanded,
			isSecondaryNavExpanded: isSecondaryNavExpanded,
			setIsSecondaryNavExpanded: setIsSecondaryNavExpanded,
			setIsMainNavPopupExpanded: setIsMainNavPopupExpanded,
			translationFunc: t,
			submitAction,
		}),
		[
			selectedModule,
			setSelectedModule,
			selectedContent,
			isMainNavExpanded,
			isMainNavPopupExpanded,
			isSecondaryNavExpanded,
		]
	)

	// const handleResize1 = () => {
	// 	// setIsMainNavPopupExpanded(false)
	// 	console.log("tchau")
	// }
	// useEffect(() => {
	// 	handleResize1()
	// 	window.addEventListener("resize", debounce(handleResize1, 100))
	// 	return () => {
	// 		window.removeEventListener("resize", handleResize1)
	// 	}
	// }, [])

	return (
		<PageLayoutContext.Provider value={memoizedContextValue}>
			<div className={`flex h-screen w-screen flex-row`}>{children}</div>
		</PageLayoutContext.Provider>
	)
}
function PageLayout<TAppFeatures>(props: IPageLayoutProps<TAppFeatures>) {
	// const PageLayout: FC<IPageLayoutProps<any>> = (props) => {
	const { company, contents, defaultModule, children, t, submitAction } = props
	if (company === undefined) {
		return <div> {`Server error: unkown company`}</div>
	}
	return (
		<ComponentBody
			contents={contents}
			defaultModule={defaultModule}
			children={children}
			t={t}
			submitAction={submitAction}
		/>
	)
}

export function usePageLayoutContext(): IPageLayoutContext {
	const context = useContext(PageLayoutContext)
	if (context === undefined) {
		throw new Error("This component must be used within a <PageLayout> component.")
	}
	return context
}

const PageLayoutNamespace = Object.assign(PageLayout, {
	MainNav: MainNav,
	SecondaryNav: SecondaryNav,
	BodyLayout: BodyLayout,
})
export { HeaderLayout, PageLayoutNamespace as PageLayout }
