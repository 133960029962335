import { Pages } from "@utilityTypes/types"

export const DIPAI: Pages = {
	"FLEET OVERVIEW": {
		page_ref: "HomePage/HomePage",
		page_url: "fleet overview/*",
		page_links: ["FLEET OVERVIEW", "CLIENTS", "DATABASE", "DEVICES", "MAINTENANCE"],
	},
	CLIENTS: {
		page_ref: "ClientsPage/ClientsPage",
		page_url: "clients/*",
		page_links: ["FLEET OVERVIEW", "CLIENTS", "DATABASE", "DEVICES", "MAINTENANCE"],
	},
	DATABASE: {
		page_ref: "DatabasePage/DatabasePage",
		page_url: "database",
		page_links: ["FLEET OVERVIEW", "CLIENTS", "DATABASE", "DEVICES", "MAINTENANCE"],
	},
	DEVICES: {
		page_ref: "DevicesPage/DevicesPage",
		page_url: "devices",
		page_links: ["FLEET OVERVIEW", "CLIENTS", "DATABASE", "DEVICES", "MAINTENANCE"],
	},
	MAINTENANCE: {
		page_ref: "MaintenancePage/MaintenancePage",
		page_url: "maintenance",
		page_links: ["FLEET OVERVIEW", "CLIENTS", "DATABASE", "DEVICES", "MAINTENANCE"],
	},
}

export const MAINTENANCE: Pages = {}

export const FLEET_OVERVIEW: Pages = {
	MAP: {
		page_ref: "soelvtrans/MapPage/MapPageSoelvtrans",
		page_url: "*",
		page_links: ["HOME", "ADMIN", "DASHBOARD", "FRAKTBREV"],
		sub_pages: {
			SHIP: {
				page_ref: "soelvtrans/ShipPage/ShipPageSoelvtrans",
				page_url: ":plantId/*",
				page_links: ["HOME", "ADMIN", "DASHBOARD", "FRAKTBREV"],
			},
		},
	},
}

export const OPERATION: Pages = {}

export const OPERATION2: Pages = {
	DASHBOARD: {
		page_ref: "sop/DashboardPage/DashboardPage",
		page_url: ":moduleName/*",
		page_links: ["HOME"],
		sub_pages: {},
	},
}

// TODO: implement fallback page
export const FALL_BACK: Pages = {
	NOT_FOUND: {
		page_ref: "NotFoun/NotFound",
		page_url: "*",
		page_links: ["HOME"],
		sub_pages: {},
	},
}
