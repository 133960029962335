import { RootState } from "@redux/app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type UniSeaVesselsList = {
	name: string
	id: number
	firstTimestamp: number
}[]

const initialState: UniSeaVesselsList = []

const uniSeaVesselsSlice = createSlice({
	name: "uniSeaVessels",
	initialState,
	reducers: {
		setUniSeaVessels(state, action: PayloadAction<UniSeaVesselsList>) {
			return action.payload
		},
	},
})

export const getUniSeaVessels = (state: RootState) => state.uniSeaVessels

export const { setUniSeaVessels } = uniSeaVesselsSlice.actions

export default uniSeaVesselsSlice.reducer
