import { VariantProps, cva } from "class-variance-authority"
import { DetailedHTMLProps, HTMLAttributes, forwardRef } from "react"
import { cn } from "../../lib/utils"

const pageCardSectionVariants = cva("justify-left flex min-h-[45px] w-auto flex-row text-body", {
	variants: {
		variant: {
			header: "mb-3 border-b-1 pb-3",
			headerSimple: "",
			footer: "mt-3 border-t-1 pt-3",
		},
	},
})

export interface IPageCardSectionProps
	extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
		VariantProps<typeof pageCardSectionVariants> {
	label?: string
}

const PageCardSection = forwardRef<HTMLDivElement, IPageCardSectionProps>((props, ref) => {
	const { label, className, children, variant, ...rest } = props

	return (
		<div ref={ref} className={cn(pageCardSectionVariants({ variant, className }))} {...rest}>
			{label !== undefined ? (
				<p className={`mr-8 min-w-fit content-center font-semibold`}>{label}</p>
			) : null}
			{children !== undefined &&
			(Array.isArray(children) === true ? children.some((c) => c !== null) : true) ? (
				<div className={`flex w-full flex-row justify-between`}>{children}</div>
			) : null}
		</div>
	)
})

export { PageCardSection, pageCardSectionVariants }
